import Vue from "vue"

import { store } from "@/config"
import { isEmpty } from "lodash-es"

window.VUE = Vue

const COOKIES_TO_DELETE = ["api.client", "api.accessToken", "api.uid", "api.tokenType", "api.expiry", "fingerprint"]
const COOKIES_TO_SET = COOKIES_TO_DELETE.filter(c => c !== "fingerprint")

export const clearAuthCookies = () => {
  COOKIES_TO_DELETE.forEach(cookie => {
    Vue.cookie.delete(cookie)
  })
}

export const getAuthHeaders = () => {
  return {
    client: Vue.cookie.get("api.client"),
    uid: Vue.cookie.get("api.uid"),
    fingerprint: Vue.cookie.get("fingerprint"),
    "access-token": getAccessToken(),
    "token-type": Vue.cookie.get("api.tokenType")
  }
}

export const getAccessToken = () => {
  return Vue.cookie.get("api.accessToken")
}

export const setAuthCookies = values => {
  COOKIES_TO_SET.forEach(cookie => {
    const valueKey = cookie.split(".")[1]
    Vue.cookie.set(cookie, values[valueKey])
  })
}

export const isLoggedIn = () => {
  return !isEmpty(store.state.currentUser)
}
